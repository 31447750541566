import {Fragment, memo, useCallback, useState} from "react";
import {AppBar, Box, Button, Container, Divider, Drawer, IconButton, List, ListItemButton, ListItemText, Toolbar, Typography, useMediaQuery, useScrollTrigger} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Close, Menu} from "@mui/icons-material";
import tuinenJosLogo from "../../assets/images/Logo.JPG";

const navbar = memo(() => {
    const {pathname} = useLocation();

    const isSmartphone = useMediaQuery("only screen and (max-width:680px)");
    const isAtTopOffPage = useScrollTrigger({
        disableHysteresis: true,
        threshold:         0,
    });

    const [drawerIsOpened, setDrawerIsOpened] = useState(false);
    const toggleNavdrawer = useCallback(() => {
        setDrawerIsOpened(!drawerIsOpened);
    }, [drawerIsOpened, setDrawerIsOpened]);
    const closeNavdrawer = useCallback(() => {
        setDrawerIsOpened(false);
    }, [setDrawerIsOpened]);


    const logo = tuinenJosLogo;
    const buttons = [
        {
            displayText: "STARTPAGINA",
            link:        "/",
            disabled:    false,
            color:       "inherit",
        },
        {
            displayText: "OVER MIJ",
            link:        "/overmij",
            disabled:    false,
            color:       "inherit",
        },
        {
            displayText: "CONTACT",
            link:        pathname,
            disabled:    false,
            color:       "inherit",
            options:     {state: {contact: {isOpen: true}}, replace: true},
        },
    ];

    return <Fragment>
        <AppBar component={"header"} position={"sticky"} color={"inherit"} elevation={isAtTopOffPage ? 4 : 1}
                enableColorOnDark sx={{marginBottom: 1}}>
            <Container>
                <Toolbar disableGutters variant={isAtTopOffPage ? "dense" : "regular"}>
                    <Box sx={{flexGrow: 1, paddingTop: 1}}>
                        <Link to="/"><img src={logo} alt={"Tuinen jos"}
                                          height={!isAtTopOffPage ? "70rem" : "30rem"}/></Link>
                    </Box>
                    {
                        !isSmartphone &&
                        buttons
                            .map(button => <NavbarButton key={button.displayText} {...button}/>)
                    }
                    {
                        isSmartphone &&
                        <IconButton color={"inherit"} onClick={toggleNavdrawer} edge={"end"}>
                            <Menu/>
                        </IconButton>
                    }
                </Toolbar>
            </Container>
        </AppBar>
        <Drawer
            open={!!isSmartphone && drawerIsOpened}
            anchor={"right"}
            keepMounted
        >
            <Toolbar variant={"dense"}
                     sx={{width: "100vw"}}>
                <Box sx={{flexGrow: 1, paddingTop: 1}}>
                    <Link to="/"><img src={logo} alt={"Tuinen jos"}
                                      height={!isAtTopOffPage ? "70rem" : "30rem"}/></Link>
                </Box>
                <IconButton color={"inherit"} onClick={closeNavdrawer} edge={"end"}>
                    <Close/>
                </IconButton>
            </Toolbar>
            <Divider/>
            <List>
                {
                    buttons
                        .map(button => <NavdrawerButton key={button.displayText} {...button} onClose={closeNavdrawer}/>)
                }
            </List>
        </Drawer>
    </Fragment>;
});

const NavbarButton = memo(({displayText = "", link = "", disabled = "", color = "inherit", options}) => {
    const navigate = useNavigate();

    return <Button disabled={disabled} color={color} onClick={() => navigate(link, options)}>
        <Typography variant={"button"} noWrap>
            {displayText}
        </Typography>
    </Button>;
});

const NavdrawerButton = memo(({displayText = "", link = "", disabled = "", color = "inherit", onClose, options}) => {
    const navigate = useNavigate();

    return <ListItemButton disabled={disabled} color={color} onClick={() => {
        navigate(link, options);
        onClose();
    }}>
        <ListItemText primary={displayText}/>
    </ListItemButton>;
});

export default navbar;