import {memo, useCallback} from "react";
import {Card, CardHeader, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {Call, Close, FacebookOutlined, Mail, Place} from "@mui/icons-material";

const contactModal = memo(() => {
    const {state, pathname} = useLocation();

    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate(pathname, {replace: true});
    }, [navigate, pathname]);

    return <Modal open={!!state?.contact?.isOpen}
                  onClose={handleClose}
                  sx={{
                      position:       "fixed",
                      top:            0,
                      left:           0,
                      height:         "100vh",
                      width:          "100vw",
                      display:        "flex",
                      alignItems:     "center",
                      justifyContent: "center",
                  }}>
        <Card>
            <CardHeader title={"Contact"} subheader={"Via hier kan je me contacteren:"}
                        action={<IconButton onClick={handleClose}><Close/></IconButton>}/>
            <List>
                <ListItemButton component={"a"} href={"tel:+32472231049"}>
                    <ListItemIcon><Call/></ListItemIcon>
                    <ListItemText primary={"0472 23 10 49"} secondary={"Bellen"}/>
                </ListItemButton>
                <ListItemButton component={"a"} href={"mailto:jos@tuinenjos.be"}>
                    <ListItemIcon><Mail/></ListItemIcon>
                    <ListItemText primary={"jos@tuinenjos.be"} secondary={"Een mail sturen"}/>
                </ListItemButton>
                <ListItemButton component={"a"} href={"https://www.facebook.com/tuinenjos"} target={"_blank"}>
                    <ListItemIcon><FacebookOutlined/></ListItemIcon>
                    <ListItemText primary={"Facebook"}/>
                </ListItemButton>
                <ListItem>
                    <ListItemIcon><Place/></ListItemIcon>
                    <ListItemText primary={"Izegem"}/>
                </ListItem>
            </List>
        </Card>
    </Modal>;
});

export default contactModal;