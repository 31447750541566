import "./assets/css/global.css"
import {Fragment, lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Container, createTheme, CssBaseline, LinearProgress, ThemeProvider} from "@mui/material";
import {ContactModal, Footer, GreenLeafs, Navbar} from "./components";


const Error404 = lazy(() => import("./containers/404"));

const Homepage = lazy(() => import("./containers/homepage"));
const AboutMe = lazy(() => import("./containers/aboutMe"));
const Aanleg = lazy(() => import("./containers/projects/aanleg"));
const Onderhoud = lazy(() => import("./containers/projects/onderhoud"));
const VellenVanBomen = lazy(() => import("./containers/projects/vellenVanBomen"));

function App() {
    const theme = createTheme({
        palette:    {
            common:               {
                black: "#1C1B1F", white: "#FFFBFE",
            },
            primary:              {
                main:         "#9CC53B",
                contrastText: "#FFFFFF",
            }, contrastThreshold: 3, tonalOffset: 0.2,
        },
        typography: {
            fontFamily: [
                            '"Kanit"',
                            '-apple-system',
                            'BlinkMacSystemFont',
                            '"Segoe UI"',
                            'Roboto',
                            '"Helvetica Neue"',
                            'Arial',
                            'sans-serif',
                            '"Apple Color Emoji"',
                            '"Segoe UI Emoji"',
                            '"Segoe UI Symbol"',
                        ].join(','),
        },
    });

    return <Fragment>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GreenLeafs/>
            <Router>
                <Navbar/>
                <ContactModal/>
                <Container component={"main"}>
                    <Suspense fallback={<LinearProgress/>}>
                        <Routes>
                            <Route path={"/"}>
                                <Route index element={<Homepage/>}/>
                                <Route path={"/overmij"} element={<AboutMe/>}/>
                                <Route path={"/aanleg"} element={<Aanleg/>}/>
                                <Route path={"/onderhoud"} element={<Onderhoud/>}/>
                                <Route path={"/vellenvanbomen"} element={<VellenVanBomen/>}/>
                                <Route path={"*"} element={<Error404/>}/>
                            </Route>
                        </Routes>
                    </Suspense>
                </Container>
                <Footer/>
            </Router>
        </ThemeProvider>
    </Fragment>;
}

export default App;
