import {Fragment, memo, useCallback} from "react";
import {AppBar, Box, Button, Container, IconButton, Toolbar, Typography, useMediaQuery} from "@mui/material";
import {Call, FacebookOutlined, Favorite, Mail, Place} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import {useLocation, useNavigate} from "react-router-dom";

const footer = memo(() => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const handleOpenConctact = useCallback(() => {
        navigate(pathname, {state: {contact: {isOpen: true}}, replace: true});
    }, [navigate, pathname]);

    const isSmartphone = useMediaQuery("only screen and (max-width:680px)");

    return <AppBar position={"static"} color={"inherit"} elevation={0} enableColorOnDark
                   sx={{background: "transparent", marginTop: "auto", flexShrink: 0}}>
        <Container>
            <Toolbar disableGutters>
                <Typography variant={"overline"} sx={{flexGrow: 1}} noWrap>
                    Made with <Favorite fontSize={"inherit"} sx={{color: red.A400}}/>
                    , by <Button size={"small"} variant={"text"} href={"https://web-4-you.be/"} hrefLang={"vls"}
                                 target={"_blank"}>
                    <Typography variant={"overline"}>WEB4YOU</Typography>
                </Button>
                </Typography>
                {!isSmartphone && <Fragment>
                    <IconButton target={"_blank"} href={"https://www.facebook.com/tuinenjos"} color={"inherit"}>
                        <FacebookOutlined/>
                    </IconButton>
                    <Button startIcon={<Call/>} color={"inherit"} href={"tel:+32472231049"}
                            sx={{marginLeft: 2}}>
                        <Typography variant={"button"} noWrap>
                            0472 23 10 49
                        </Typography>
                    </Button>
                    <Button startIcon={<Mail/>} color={"inherit"} href={"mailto:jos@tuinenjos.be"}
                            sx={{marginLeft: 2}}>
                        <Typography variant={"button"} noWrap>
                            jos@tuinenjos.be
                        </Typography>
                    </Button>
                    <Box sx={{marginLeft: 2, marginRight: 1}}>
                        <Place color={"inherit"} fontSize={"inherit"}/>
                    </Box>
                    <Typography variant={"button"} noWrap>
                        Izegem
                    </Typography>
                </Fragment>}
                {!!isSmartphone && <Button color={"inherit"} onClick={handleOpenConctact}>
                    contact
                </Button>}
            </Toolbar>
        </Container>
    </AppBar>;
});

export default footer;