import './slideshow.css';
import React, {Fragment, memo, useCallback, useEffect, useMemo, useState} from "react";
import Foto1 from '../../assets/images/IMG_3242.jpg';
import Foto2 from '../../assets/images/IMG_6496.HEIC.webp';
import Foto3 from '../../assets/images/IMG_5854.jpg';
import {Card, CardMedia, Collapse, Tab, Tabs} from "@mui/material";
import {Circle} from "@mui/icons-material";

const slideshow = memo(() => {
    const fotos = useMemo(() => [
        {
            name: "IetsVanFoto1",
            alt: "alt1",
            image: Foto1,
        },
        {
            name: "IetsVanFoto2",
            alt: "alt2",
            image: Foto2,
        },
        {
            name: "IetsVanFoto3",
            alt: "alt3",
            image: Foto3,
            /*TODO foto3 moet nog aangepast worden, jos ging nog de foto doorsturen */
        },
    ], []);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleSelectIndex = useCallback((event, index) => {
        setSelectedIndex(index);
    }, [setSelectedIndex]);


    const delay = 4000;
    useEffect(() => {
        fotos
            .forEach((foto, index) => {
                setTimeout(() => {
                    setInterval(() => handleSelectIndex(null, index), delay * fotos.length);
                }, delay * index);
            });
    }, [fotos, handleSelectIndex]);

    const tabProps = (index) => {
        return {
            id: "slideshow-tab-" + index,
            "aria-controls": "slideshow-tabpanel-" + index,
        };
    };

    return <Fragment>
        {
            fotos
                .map((foto, index) => {
                    return <Collapse key={index} in={selectedIndex === index}>
                        <div
                            role="tabpanel"
                            id={"slideshow-tabpanel-" + index}
                            aria-labelledby={"slideshow-tabpanel-" + index}
                            aria-hidden={selectedIndex !== index}
                        >
                            <Card elevation={0}>
                                <CardMedia
                                    component={"img"}
                                    image={foto.image}
                                    alt={foto.alt}
                                    sx={{aspectRatio: "3/1"}}
                                />
                            </Card>
                        </div>
                    </Collapse>;
                })
        }
        <Tabs value={selectedIndex} onChange={handleSelectIndex} centered
              TabIndicatorProps={{style: {display: "none"}}}>
            {
                fotos
                    .map((foto, index) => <Tab key={index} tabIndex={index} icon={<Circle/>} disableRipple
                                               disableTouchRipple {...tabProps(index)}/>)
            }
        </Tabs>
    </Fragment>;
});

export default slideshow;