import {memo} from "react";
import {Box} from "@mui/material";

import backgroundLeafsImage from "../../assets/images/bladeren.JPG";

const leafs = memo(() => {
    return <Box sx={{
        position:       "fixed",
        top:            0,
        left:           0,
        height:         "100vh",
        width:          "100vw",
        display:        "flex",
        alignItems:     "center",
        justifyContent: "center",
        zIndex:         -1,
    }}>
        <img src={backgroundLeafsImage} style={{height: "100vh", width: "100vw"}} alt={" "}/>
    </Box>;
});

export default leafs;